import React from "react";
import moment from "moment";

function ListView({ employees, incidences, setIsVisible, key1, key2, label1, label2 }) {

	incidences.sort((a, b) => {

		const dateA = moment.utc(`${a.date} ${a[key1]}`, "YYYY-MM-DD HH:mm:ss");
		const dateB = moment.utc(`${b.date} ${b[key1]}`, "YYYY-MM-DD HH:mm:ss");

		if(dateA > dateB) return -1
        if(dateA < dateB) return 1
		return 0

	});

	return (
		<>
			<div class="table-responsive datatable-custom" style={{ overflow: "auto", maxHeight: "70vh" }} >
				<table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
					<thead style={{ position: "sticky", top: "-3px", zIndex: 1 }}>
						<tr role="row">
							<th>ID</th>
							<th>Empleado</th>
                            <th>{label1}</th>
                            <th>{label2}</th>
						</tr>
					</thead>
					<tbody>
						{
							incidences.map((item) => (
								<tr key={item.employee_id} role="row">
									<td>{employees[item.employee_id]?.key}</td>
									<td style={{ whiteSpace: "nowrap" }}>{employees[item.employee_id]?.firstname} {employees[item.employee_id]?.dad_lastname} {employees[item.employee_id]?.mom_lastname}</td>
									<td>{item[key1]}</td>
                                    <td>{moment.utc(item[key2]).format("HH:mm:ss")}</td>
								</tr>
							))
						}
					</tbody>
				</table>
			</div>
			<div class="mt-3">
				<button type="button" class="btn btn-primary" style={{ marginLeft: "20px" }} onClick={() => setIsVisible(false)}>
					<i class="bi bi-x" style={{ marginRight: "10px" }}></i>
					Cerrar
				</button>
			</div >
		</>

	);
}

export default ListView;