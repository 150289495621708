import React, { useState, useEffect, useContext } from "react"
import { toast } from "react-toastify"
import { usePut, useDelete, useGetCall, fetchPost } from "utils/api";
import Loading from "helpers/Loading";
import { LoadingContext } from "helpers/LoadingContext";
import { showResponseMessage } from "utils/message";
import View from "components/crh/devices/List.view"

function DevicesList({
	countRef,
	refresh,
	search,
	company,
	addEditDeviceModal,
	addBiophotosModal
}) {

	const { loading, setLoading } = useContext(LoadingContext);

	const [refreshValue, setRefreshValue] = useState(null);
	const [refreshDelete, setRefreshDelete] = useState(null);
	const [devices, setDevices] = useState([])

	if(refresh !== refreshValue) setRefreshValue(refresh);

	if(countRef != null && countRef.current != null)
		countRef.current.innerHTML = devices.length

	const [callDevices, reqDevices] = useGetCall("/devices", {
		onCompleted: (response) => {
			setLoading(false);
			setDevices(response.data)
		},
		onError: (error) => {
			setLoading(false);
			showResponseMessage(error.status, "devices", "lectores", "read")
		}
	})

	const [callUpdate, reqUpdate] = usePut("/devices", {
		onCompleted: () => {
			if (!toast.isActive("toast-update-device"))
				toast.success("Dispositivo actualizado", { toastId: "toast-update-device" })

			setRefreshDelete(Math.random())
		},
		onError: (error) => {
			console.error(error)
			showResponseMessage(error.status, "devices", "lectores", "edit")
		}
	})

	const [callDelete, reqDelete] = useDelete("/devices", {
		onCompleted: () => {
			if (!toast.isActive("delete-device"))
				toast.success("Dispositivo eliminado", { toastId: "delete-device" })
			setRefreshDelete(Math.random())
		},
		onError: (error) => {
			console.error(error)
			showResponseMessage(error.status, "devices", "lectores", "delete")
		}
	})

	useEffect(() => {
		setLoading(true);
		callDevices({ search, company });
	}, [refreshDelete, refreshValue, search])

	const onDelete = (id) => {
		fetchPost("/devices/check_permission", { type: "delete" }).then((response) => {
			if (response.data) {
				const data = response.data;
				if (data.isAllowed === true) {
					if (window.confirm("¿Estás seguro de eliminar el dispositivo?")) {
						callDelete({ id: id })
					}
				} else {
					if (!toast.isActive("toast-delete-device-unauthorized")) {
						toast.error("Error, no tienes los permisos para eliminar dispositivos", { toastId: "toast-delete-device-unauthorized" })
					}
				}
			}
		})
	}

	const onEdit = (id) => addEditDeviceModal(id)
	const onViewBiophotos = (id) => addBiophotosModal(id)
	const onStatusChange = (device, status) => callUpdate({ ...device, status })

	if (loading) return <Loading />

	return (
		<View
			onStatusChange={onStatusChange}
			devices={devices}
			onDelete={onDelete}
			onEdit={onEdit}
			onViewBiophotos={onViewBiophotos}
		/>
	)

}

export default DevicesList